import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { WaitinglistBackgroundImage, KlavyioContainer } from './styles';

export const WaitlistLayout: React.FC = () => {
  const bgData = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "Waitlist/waitlist_bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <WaitinglistBackgroundImage fluid={bgData.desktop?.childImageSharp.fluid}>
        <KlavyioContainer>
          <div className="klaviyo-form-VezquJ" />
        </KlavyioContainer>
      </WaitinglistBackgroundImage>
    </>
  );
};
