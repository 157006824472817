import React from 'react';
import { WaitlistLayout } from 'components/Waitlist/WaitlistLayout';
import { PageProps } from 'gatsby';
import { Layout } from '../components/shared/Layout';

const Waitlist: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname} barColor="darkTransparent">
      <WaitlistLayout />
    </Layout>
  );
};

export default Waitlist;
