import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { Container } from '@tuunetech/tuune-components';

export const WaitinglistBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  margin: 0;
`;

export const KlavyioContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
